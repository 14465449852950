body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.progressbar-container {
  background-color: white;
  /* background-color: '#EFEFEF'; */
  border-radius: 4px;
  height: 4px;
}
.progressbar-progress {
  border-radius: 4px;
  height: 4px !important;
}

.MuiPaper-elevation {
  max-width: 448px !important;
}


.dialogPaper {
  min-width: 100vw;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background-color: transparent !important;
}